$dark: #1b1e21;
$gray: #27292d;
$medium-gray: #D7DEE8;
$light-gray: #333539;

$dark-blue: #22324e;
$medium-blue: #1764d9;
$paperbox-blue: #0085FF;

$red: #d71f1f;


$gradient-blue: linear-gradient(317.38deg, #2753A9 1.68%, #0085FF 149.06%);
$gradient-blue-faded: linear-gradient(317.38deg, rgba(39, 83, 169, 0.6) 1.68%, rgba(0, 133, 255, 0.6) 149.06%);
$gradient-firebase: linear-gradient(317.38deg, #ffca2a 1.68%, #ffa60f 149.06%);

$shadow-main: rgba(0, 0, 0, 0.24) 0px 3px 8px;
//$shadow-main: 2px 2px 2px #323439;
