@import '../../styles/vars/_vars';


.header {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;
  padding: 0 24px;

}


.header_name {
  display: flex;
  justify-content: center;

  svg {
    color: $paperbox-blue;
    margin-left: 6px;
    width: 16px;
    height: 16px;
  }
}


.table {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  margin-top: 80px;
  text-align: center;

  .tenant_name {
    font-size: 26px;

  }

}


.body {
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 0;

}


.toolbar {
  display: flex;
  align-items: center;
  position: absolute;
  top: 40px;
  right: 50px;

  height: 45px;
  padding: 5px 25px;
  background: $gray;
  border-radius: 5px;
  box-shadow: 0 4px 14px rgba(13, 21, 33, 0.2);
  gap: 12px;

  &_counter {

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
    min-width: 30px;
    padding: 0 8px;
    vertical-align: center;
    color: white;
    background: $gradient-blue;
    border-radius: 5px;

  }

  &_buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-left: 20px;
  }

  &_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    background: rgba($dark, 0.4);
    border-radius: 5px;

    & + & {
      margin-left: 10px;
    }

    &:hover {
      cursor: pointer;
      background: rgba($dark, 0.9);
      outline: none;
    }

    &:focus {
      outline: none;
    }

    &:active {
      background: rgba($dark, 0.4);

      path {
        opacity: 0.5;
      }
    }

    &:last-of-type {
      margin-right: 10px;
    }
  }
}


.hover_icon {
  width: 0;
  min-width: 0;
  height: auto;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.1s ease, min-width 0.1s ease;
  margin-left: 6px;
}


.document_item {
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  padding: 0 24px;
  border-radius: 5px;
  cursor: default;

  background: $gray;

  &:hover {
    background: $light-gray;
  }

  &__delete {
    background: #d71f1f;
    opacity: 0.7;
  }

  &_row_item {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: auto;
    padding: 0 12px;
    overflow: hidden;

    &:hover {
      background: $gradient-blue;
      border-radius: 5px;

      .hover_icon {
        width: 20px;
        min-width: 20px;

      }
    }

  }

  &_name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    align-self: flex-start;

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__id {
      font-style: italic;
    }
  }

  &_tenant_name {
    cursor: pointer;

  }

  &_inbox_name {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  &_upload {

  }

  &_status {
    display: flex;
    justify-content: center;

    &_tag {
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      border-radius: 5px;
      background: $dark;
      height: 28px;
      overflow: hidden;

      &__sub {
        height: 28px;
        width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid $light-gray;
        margin-left: -6px;
      }

      &__main {
        white-space: nowrap;
        padding: 7px 12px;
      }
    }
  }

  &_actions {
    display: flex;
    flex-direction: row;
    width: 15%;
    min-width: 200px;
    justify-content: center;
    gap: 8px;
  }

  &_action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 5px;
    background: $dark;

    &:hover {
      background: $gradient-blue;
    }

    &__delete:hover {
      background: $red;
    }

    &__pending {
      svg {
        animation: rotate 1s infinite linear;
      }

      opacity: 0.5;
      pointer-events: none;
    }

    &__disabled, &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.status_tooltip {
  display: flex;
  flex-direction: column;
  text-align: start;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 2;
  max-height: 80vh;
  overflow: hidden;

  &_wrapper {
    opacity: 1 !important;
    box-shadow: $shadow-main;
  }

  &_header {
    border-bottom: 1px solid $light-gray;
    margin-bottom: 10px;
  }

  &_row {
    display: flex;
    justify-content: space-between;
    gap: 50px;
  }

  //&_row_dot {
  //  margin-top: 8px;
  //  border-radius: 10px;
  //  width: 7px;
  //  height: 7px;
  //  position: absolute;
  //  left: 5px;
  //  background: $medium-gray;
  //  opacity: 0.7;
  //
  //
  //  &:not(&__first)::after {
  //    position: absolute;
  //    z-index: -1;
  //
  //    content: ' ';
  //    width: 1px;
  //    height: 26px;
  //    left: 3px;
  //    top: -26px;
  //    background: $medium-gray;
  //    opacity: 0.5;
  //  }
  //
  //}
}


.token {
  font-size: 10px;
  font-weight: 700;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  padding: 4px;
  text-align: center;
  vertical-align: center;

  border-radius: 3px;
  background: $gradient-blue;

}


.flex_col {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}


.first {
  width: 20px;
}


.second {
  width: 20%;
  max-width: 20%;
  text-align: start;

}


.third {
  width: 15%;

}


.fourth {
  width: 18%;

}


.fifth {
  min-width: 140px;

}


.sixth {
  width: 15%;

}


.seventh {
  min-width: 200px;
}
