@import "../vars/_vars";

body {
  font-family: $base-font;
  overflow-y: hidden;
  background-color: white;
}

html {
  scroll-behavior: smooth;


}

::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 5px;
}
