@import '../../../styles/vars/_vars';


.options {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
  gap: 12px;
}


.option_wrapper {
  position: relative;

}


.option_inner {
  position: absolute;
  top: 38px;
  left: 0;
  z-index: 10;
}


.option {
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-grow: 0;
  width: auto;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
  background: $light-gray;
  gap: 4px;

  b {
    font-weight: 800;
  }

  &__basic {
    white-space: nowrap;
    flex: 1;
    font-weight: 500;

    svg {
      width: 15px;
    }
  }
}


.option_icon {
  width: 12px;
  height: 100%;
  margin-left: 5px;
  transform: rotate(180deg);

}


.new_wrapper {
  position: relative;
}


.new {

  display: flex;
  flex-direction: column;
  min-width: 200px;
  height: auto;
  padding: 5px;
  border: 1px solid $light-gray;
  border-radius: 5px;
  background-color: $gray;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  gap: 8px;

}


.new_inner {
  position: absolute;
  top: 38px;
  left: 0;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  z-index: 9;

}


.new_option {
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: $light-gray;
  }

  &__active {
    background: $light-gray;

  }
}


.option_dropdown {
  min-width: 350px;
  padding: 20px;
  border: 1px solid $light-gray;
  border-radius: 5px;
  background-color: $gray;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  z-index: 10;

  .title {
    font-size: 16px;
    font-weight: 500;
  }
}


.option_dropdown_options {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
}


.option_dropdown_option {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}


.option_dropdown_dates {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  gap: 8px;

  select {

  }
}


.input {
  font-family: inherit;
  font-size: 14px;
  width: 100%;
  padding: 12px;
  color: white;
  border: none;
  border-radius: 5px;
  outline: none;
  background: $light-gray;
  box-shadow: unset;
  color-scheme: dark;
  appearance: none;
}


.buttons {
  font-size: 14px;
  display: flex;
  justify-content: space-evenly;
  gap: 8px;
}


.button {
  font-weight: 500;
  width: 100%;
  padding: 12px 20px;
  white-space: nowrap;
  border-radius: 5px;
  background-color: $light-gray;

  svg {
    width: 16px;
    height: auto;
  }

  &__icon {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: 40px;
    padding: 0;
    aspect-ratio: 1;
  }

  &__alt {
    background-color: $paperbox-blue;
  }
}
