@import '../../../styles/vars/_vars';

.option {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 8px;
  color: white;
  border-radius: 5px;


  &__active {
    background: $gradient-blue;

    .counter {
      background: $light-gray;
    }
  }

  &:hover:not(&__active) {
    background: $gradient-blue-faded;

    .counter {
      background: $light-gray;
    }
  }

  &_title {
    font-size: 16px;
    font-weight: 500;
    margin-top: 2px;
  }

  &_icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &_label {

  }

}

.counter {
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  margin-right: 2px;
  margin-left: auto;
  padding: 5px 9px 3px;

  text-align: center;
  color: white;
  border-radius: 10px;
  background: $gradient-blue;

}
