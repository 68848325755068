@import '../../styles/vars/_vars';


.input {
  font-family: inherit;
  font-size: 14px;
  width: 100%;
  padding: 12px;
  color: white;
  border: none;
  border-radius: 5px;
  outline: none;
  background: $light-gray;
  box-shadow: unset;
  color-scheme: dark;
  appearance: none;
}


.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  label {
    margin-top: 20px;
  }
}


.slider {
  margin-bottom: 20px;
}

