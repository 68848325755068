@import '../../../styles/vars/_vars';


.container {
  display: flex;
  align-items: center;

  .slider {
    margin-right: 5px;
  }

  &__left {
    flex-direction: row-reverse;

    .slider {
      margin-right: 0;
      margin-left: 16px;
    }
  }
}


.slider {
  width: 100%;
  height: 4px;
  transition: background 450ms ease-in;
  border-radius: 8px;
  outline: none;
  background: linear-gradient(to right, $paperbox-blue 0%, $paperbox-blue 50%, $dark 50%, $dark 100%);
  -webkit-appearance: none;

  &:disabled {
    background: linear-gradient(to right, $gray 0%, $gray 50%, $light-gray 50%, $light-gray 100%);

    &::-webkit-slider-thumb {
      background: var(--color);
    }
  }

  &::-webkit-slider-thumb {
    width: 10px;
    height: 10px;
    cursor: pointer;
    border-radius: 10px;
    background: var(--color);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -webkit-appearance: none;

  }

}


.number {
  font-weight: 500;
  width: 45px;

  height: 35px;
  margin-right: -10px;
  text-align: right;
  color: white;
  border: none;
  background: none;

  &:active, &:focus {
    border-color: $paperbox-blue;
    outline: none;
  }

  &:disabled {
    opacity: 0.4;
  }

  &__light {
    border: none;
    background: white;
  }

  &__small {
    height: 25px;
    padding-right: 5px;
    padding-left: 5px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }
}
