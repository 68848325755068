@import '../../../styles/vars/_vars';


.container {
  //overflow: hidden;
  width: 450px;
  animation: scaleIn 0.3s ease-in-out forwards;
  color: white;
  border-radius: 5px;
  background: $gray;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 16px;

}


.background {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  animation: fadeIn 0.3s;
  background: rgba(0, 0, 0, 0.2);

}


.title {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}


.bottom {
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;

  p {
    font-size: 15px;
    line-height: 1.5;
  }
}


.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  gap: 10px;

  svg {
    width: 36px;
    height: 36px;

    path {
      stroke-width: 1.5px;
    }
  }
}


.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 20px;
}


.button {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 40px;
  transition: all 0.3s ease-in-out;
  transition-property: background-color, color, box-shadow, opacity, width;
  border-radius: 5px;
  background: $gradient-blue;
  min-width: 120px;
  height: 40px;

  &__delete {
    background: $red;
  }

  &_alt {
    color: $medium-gray;
    background: $light-gray;
  }

  &:hover {
    opacity: 0.9;
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 16px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}


.cross {

}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes scaleIn {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  100% {
    opacity: 1;

  }
}


.reprocess_container {
  min-width: 500px;
  padding: 20px;
  animation: scaleIn 0.3s ease-in-out forwards;
  color: white;
  border-radius: 5px;
  background: $gray;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;

}


.reprocess_desc {
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
}


.reprocess_options {
  font-size: 14px;
  display: flex;
  gap: 6px;
  flex-direction: column;
  min-width: 300px;
  overflow: auto;

  .row {
    padding: 10px 12px;
    border-radius: 5px;
    background-color: $light-gray;

    display: flex;
    justify-content: space-between;
  }
}


.reprocess_inboxes {
  max-height: 175px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 10px;

  div {
    border-radius: 5px;
    background-color: $light-gray;
    padding: 10px 12px;
  }
}


