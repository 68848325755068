@import '../../styles/vars/_vars';


.tools {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;

}


.search {
  outline: none;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: $gray;
  width: 300px;
  height: 31px;
  color: white;
  margin-top: 40px;
}


.content {
  margin-top: 40px;
}


.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
}


.group {

  border-bottom: 2px solid $gray;

  h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}


.tenant_groups {
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 0;
}


.tenant_rows {
  gap: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  //padding-bottom: 24px;
  //border-bottom: 1px solid $gray;
  .row_bottom {
    margin-bottom: 12px;
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid $gray;
  }

}


.card_placeholder {
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  background-color: $gray;
  box-shadow: 0 4px 14px rgba(13, 21, 33, 0.2);

}


.table {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  height: 100%;
  text-align: center;
  margin-top: 40px;

  .tenant_name {
    font-size: 26px;

  }

}


.header {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;
  padding: 0 24px;

}


.row, .tenant_row {
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  padding: 0 24px;
  border-radius: 5px;
  cursor: default;
  background: $gray;

  &:hover {
    background: $light-gray;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    width: 26px;
    border-radius: 5px;
    background: $dark;

    &:hover {
      background: $gradient-blue;
    }

  }
}


.row {
  border-radius: 0;
  position: relative;

  .first {
    padding-left: 25px;

    &::before {
      content: ' ';
      width: 25px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $dark;
      z-index: 1;
    }
  }
}


.first {
  width: 50%;
  text-align: start;

  b {
    font-weight: 800;
  }
}


.second {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 20%;
  max-width: 20%;
  text-align: start;
  gap: 8px;

}


.third {
  width: 100px;
  max-width: 100px;
  display: flex;
  justify-content: center;
}


.fourth {
  width: 30px;
  max-width: 30px;
  display: flex;
  justify-content: center;
}
