@import '../../styles/vars/_vars';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: $dark;
}

.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 370px;
  min-height: 400px;
  border-radius: 10px;
  background: $gradient-blue;
  box-shadow: 2px 2px 2px #323439;

  &__expanded {
    min-width: 100vw;
    min-height: 100vh;
    padding-top: 100px;
    transition: all 0.4s ease-in-out;
    border-radius: 0;
  }
}

.logoGroup {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  svg {
    min-width: 160px;
    height: auto;
  }

  span {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    margin-top: 2px;
    letter-spacing: 6px;
    color: white;
  }
}

.button__signIn {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  width: 220px;
  height: 37px;
  max-height: 37px;
  margin-top: 104px;
  transition: all 0.2s;
  text-align: center;
  color: white;
  border-radius: 5px;
  background: $dark;

  &__hidden {
    overflow: hidden;
    max-width: 0;
    max-height: 0;
  }

  &__pending {
    color: $dark;
    background: white;

  }


  &:hover {
    max-width: 220px;
    color: $dark;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .logoBackground {
      max-width: 220px;
      transition: max-width 0.2s, max-height 0.2s;
    }
  }

  &:active {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 3px;

    .logoBackground {
      opacity: 0.9;
    }

  }


  span {
    font-weight: 600;
    margin: 0 auto;
  }

  .logoBackground {
    position: absolute;
    z-index: 0;
    width: 100%;
    max-width: 37px;
    height: 37px;
    transition: max-width 0.2s;
    border-radius: 5px;
    background: white;

  }

  .buttonContent {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 220px;
  }

  .googleLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    border-radius: 5px;
  }
}


.error {
  margin-top: 16px;
  margin-bottom: -16px;
  background: $red;
  border-radius: 3px;
  padding: 8px 12px;
  color: white;
}
