@import '../../styles/vars/_vars';


.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 50px;
  color: white;
}


.stats {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: stretch;
  gap: 20px;

}


.sub_stats {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  gap: 20px;
  text-transform: capitalize;

  .stat {
    padding: 0px;
    background: $gray;
  }

  .stat_number {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px 0 0 7px;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    font-weight: 500;
    font-size: 16px;
    min-width: 40px;
    min-height: 40px;
  }

  .stat_text {
    font-size: 16px;
  }
}


.stat {
  padding: 15px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  background: $gradient-blue;
  gap: 20px;
  min-width: 200px;
  flex: 1;
  //max-width: 300px;
  flex-basis: 250px;

}


.stat_number {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background: white;
  color: $paperbox-blue;
  font-weight: 500;
  font-size: 22px;
  min-width: 68px;
  min-height: 40px;
}


.percentage_wrapper {
  border-radius: 10px;
  width: 100%;
  max-width: 150px;
  margin-right: 10px;
  height: 8px;
  background: $dark;
  display: flex;
  overflow: hidden;
}


.percentage_inner {
  //border-radius: 10px;
  height: 100%;
  width: 50%;
  background: $gradient-blue;

  &:not(:last-child) {
    border-right: 1px solid $dark;
  }
}


.flex_col {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;

  span {
    text-align: end;
    min-width: 30px;
  }
}


.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px;
}


.stat_text {
  font-size: 18px;
}


.status {
  background: #ADE01B;
  box-shadow: 0px 0px 4px #ADE01B;
  width: 16px;
  height: 3px;
  border-radius: 6px;

}


.status_tooltip {
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 200px;

  //max-width: 200px; // Adjust as needed
}


.status_row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}


.status_color {
  width: 12px;
  height: 12px;
  border-radius: 50%; // Makes it circular; remove for square
  margin-right: 0;
}


.status_label {
  font-weight: 500;
  color: #333;
  flex-grow: 1;
  text-transform: capitalize; // Adjusts label formatting
  margin-right: auto;
  margin-left: 0;

}


.status_count {
  font-weight: bold;
  color: #333;
  margin-left: auto;
}


.zero {
  width: 16px;
}


.first {
  width: 20%;
  max-width: 20%;
  text-align: start;
  justify-content: start;
}


.second {
  width: 13%;
}


.third {
  width: 13%;
}


.fourth {
  width: 13%;
}


.fifth {
  width: 13%;
}


.sixth {
  min-width: 130px;

}
