@import '../../../styles/vars/_vars';


.container {
  padding: 5px 15px !important;
  box-shadow: $shadow-main;
  color: black !important;
  animation: tooltip-enter 0.5s ease-in-out forwards;

  &::before {
    z-index: -1 !important;
  }
}


@keyframes tooltip-enter {

  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

