@import '../../../styles/vars/_vars';

.wrapper {
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;
  display: flex;
  width: 34px;
  height: 18px;
  cursor: pointer;
  background: rgba(45, 51, 87, 1);
  background: $light-gray;
  border-radius: 10px;
  transition: all 0.25s ease-in-out;


  &__active {
    background: $paperbox-blue;
    transition: all 0.25s ease-in-out;
  }

  &__disabled {
    opacity: 0.3;
    cursor: default;
    user-select: none;

    .slider {
      cursor: default;
    }
  }
}

.slider {
  position: absolute;
  z-index: 8;
  width: 18px;
  height: 18px;
  cursor: pointer;

  transform: translateX(0px);
  background: white;
  border: 2px solid $light-gray;
  border-radius: 10px;
  transition: all 0.25s ease-in-out;

  &__active {
    transform: translateX(16px);
    border: 2px solid $paperbox-blue;
    transition: all 0.25s ease-in-out;

  }

}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9;
  top: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;

  &__left {
    left: 0;
  }

  &__right {
    right: 0;
  }

  span {
    color: white;
  }

}
