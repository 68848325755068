@import '../../styles/vars/_vars';


.container {
  display: flex;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

main {
  display: flex;
  flex: 1;

  background: $dark;
}
