@import '../../../styles/vars/_vars';

.container {
  position: fixed;
  bottom: 20px;
  left: 10px;
  display: flex;
  overflow: hidden;
  align-items: center;
  width: 260px;
  height: 60px;
  border-radius: 5px;
  background: $dark;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .text {
    padding: 10px;
    color: white;

    .title {
      font-size: 14px;
      font-weight: 500;

    }

    p {
      font-size: 14px;
      line-height: 18px;
      margin-top: 4px;
    }


  }


  .left {
    display: flex;
    width: 42px;
    height: 100%;
    background: $gradient-blue;
  }


  .icon {
    height: auto;
    margin: 0 auto;
    width: 20px;
    color: white;
  }

  &__error {
    .left {
      background: $red;
    }

    .counter {
      background: $red;
    }
  }
}

.counter {
  position: absolute;
  background: $gradient-blue;
  transform: rotateY(180deg);
  bottom: 0;
  left: 40px;
  height: 2px;
  border-radius: 5px;
}

:global {

  .notification-enter {
    transition: transform 0.3s ease-in-out;
    transform: scale(0.8) translateX(-320px);


  }

  .notification-enter-active {
    transition: transform 0.3s ease-in-out;
    transform: scale(1) translateX(0px);


  }

  .notification-exit {
    transition: transform 0.3s ease-in-out;
    transform: scale(1) translateX(0px);


  }

  .notification-exit-active {
    transition: transform 0.3s ease-in-out;
    transform: scale(0.8) translateX(-320px);


  }
}
