@import '../../styles/vars/_vars';


.header {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 18px;
  padding: 0 24px;

}


.header_name {
  display: flex;
  justify-content: center;

  svg {
    color: $paperbox-blue;
    margin-left: 6px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
}


.table {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  margin-top: 80px;
  text-align: center;

  .tenant_name {
    font-size: 26px;

  }

}


.body {
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 0;

}


.icon {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 16px;
    height: 3px;
    border-radius: 6px;

  }
}


.first {
  width: 50px;
}


.second {
  width: 20%;
  max-width: 20%;
  text-align: start;

}


.third {
  width: 15%;

}


.fourth {
  width: 15%;

}


.fifth {
  min-width: 140px;

}


.sixth {
  min-width: 130px;

}


.row {
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  padding: 0 24px;
  border-radius: 5px;
  cursor: default;

  background: $gray;

  &:hover {
    background: $light-gray;
  }

  &_name {

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &_actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 10%;
    min-width: 130px;
    gap: 8px;
  }

  &_action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 5px;
    background: $dark;

    &:hover {
      background: $gradient-blue;
    }

    &__disabled {
      svg {
        animation: rotate 1s infinite linear;
      }

      opacity: 0.5;
      pointer-events: none;
    }
  }
}
