@import '../../../styles/vars/_vars';


.container {
  font-size: 22px;
  position: relative;
  display: block;
  width: 17px;
  height: 17px;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}


.hidden {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;

  &:disabled + div {
    cursor: not-allowed;
    opacity: 0.3;
  }
}


.radio {
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  height: 17px;
  background-color: rgba($paperbox-blue, 0.08);
  border: 1px solid rgba($paperbox-blue, 0.2);
  border-radius: 10px;

}


.hidden:focus ~ .radio {
  background-color: rgba($paperbox-blue, 0.3);
}


.hidden:checked ~ .radio {
  background-color: $paperbox-blue;
}


.dot {
  position: absolute;
  top: 6px;
  left: 6px;
  display: none;
  width: 5px;
  height: 5px;
  border-radius: 4px;
  pointer-events: none;
  background-color: white;
}


.hidden:checked ~ .dot {
  display: block;
}


.hidden:focus ~ .dot {
  display: block;
}
