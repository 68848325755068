@import '../../../styles/vars/_vars';

.container {
  font-size: 22px;
  position: relative;
  display: block;
  width: 17px;
  height: 17px;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;

}

.hidden {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;

  &:disabled + div {
    cursor: not-allowed;
    opacity: 0.3;
  }
}

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  height: 17px;
  background-color: rgba($paperbox-blue, 0.08);
  border: 1px solid rgba($paperbox-blue, 0.2);
  border-radius: 4px;

}

.hidden:checked ~ .checkbox {
  background-color: $paperbox-blue;
}

.hidden:focus ~ .checkbox {
  background-color: rgba($paperbox-blue, 0.08);
}

.hidden:indeterminate ~ .checkbox {
  background-color: $paperbox-blue;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 3px;
  display: none;
  width: 12px;
  height: auto;
  pointer-events: none;
  color: white;
}

.indeterminate {
  position: absolute;
  top: 7px;
  width: 9px;
  border-radius: 4px;
  height: 3px;
  left: 4px;
  display: none;
  pointer-events: none;
  background: white;
}

.hidden:checked ~ .checkmark {
  display: block;
}

.hidden:focus ~ .checkmark {
  display: block;
}

.hidden:indeterminate ~ .indeterminate {
  display: block;
}
