.page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.page_title {
  font-size: 24px;
  font-weight: 700;
}


.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 50px;
  color: white;
}
