@import '../../../styles/vars/_vars';


.sidebar {
  overflow: hidden;
  width: 280px;
  min-width: 280px;
  height: 100vh;
  background: $gray;

}


.logo {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 40px;
  color: white;

  svg {
    height: 25px;
  }

  span {
    font-family: 'Raleway', sans-serif;
    font-size: 10px;
    margin-top: 7px;
    letter-spacing: 2px;
  }
}


.options {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 25px;
  gap: 8px;
}


.notif {
  position: fixed;
  bottom: 30px;
  left: 232px;
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: white;

  svg {
    width: 20px;
    height: 20px;
  }
}


.sign_out {
  font-size: 14px;
  font-weight: 500;
  position: fixed;
  bottom: 32px;
  left: 32px;
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  padding: 8px 12px;
  color: white;
  border-radius: 5px;
  background: $light-gray;

  &:hover {
    background: $gradient-blue;
  }

  svg {
    width: 12px;
    height: 12px;
    margin-left: 8px;
  }
}
